.account-quick-btn-wrap{
	ul{
		list-style:none;
		margin:0 0 50px;
		padding:0;

		li{
			box-sizing: border-box;
			display:block;
			position:relative;
			margin:0 5px;
			border:2px solid #2c557f;

			a{
				padding:30px 8px;
				text-align:center;
				width:100%;
				height:100%;
				display:block;
				text-decoration:none;
				color:#000;
			}
			a:hover{
				background:#2c557f;
				color:#fff;
				text-decoration:none;
			}
		}
	}
}