/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */
// bower:scss
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */
// injector
@import "core/scss/global.scss";
@import "core/directives/ms-card/templates/template-1/template-1.scss";
@import "core/directives/ms-card/templates/template-2/template-2.scss";
@import "core/directives/ms-card/templates/template-10/template-10.scss";
@import "core/directives/ms-card/templates/template-3/template-3.scss";
@import "core/directives/ms-card/templates/template-4/template-4.scss";
@import "core/directives/ms-card/templates/template-5/template-5.scss";
@import "core/directives/ms-card/templates/template-7/template-7.scss";
@import "core/directives/ms-card/templates/template-6/template-6.scss";
@import "core/directives/ms-card/templates/template-8/template-8.scss";
@import "core/directives/ms-card/templates/template-9/template-9.scss";
@import "core/directives/ms-stepper/templates/horizontal/horizontal.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-card/ms-card.scss";
@import "core/directives/ms-info-bar/ms-info-bar.scss";
@import "core/directives/ms-form-wizard/ms-form-wizard.scss";
@import "core/directives/ms-masonry/ms-masonry.scss";
@import "core/directives/ms-material-color-picker/ms-material-color-picker.scss";
@import "core/directives/ms-nav/ms-nav.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-search-bar/ms-search-bar.scss";
@import "core/directives/ms-shortcuts/ms-shortcuts.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-stepper/ms-stepper.scss";
@import "core/directives/ms-timeline/ms-timeline.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/hedgecovest/e-commerce/views/orders/orders.scss";
@import "main/hedgecovest/e-commerce/views/order/order.scss";
@import "main/hedgecovest/e-commerce/views/product/product.scss";
@import "main/hedgecovest/e-commerce/views/products/products.scss";
@import "quick-panel/quick-panel.scss";
@import "toolbar/toolbar.scss";
@import "navigation/layouts/horizontal-navigation/navigation.scss";
@import "navigation/layouts/vertical-navigation/navigation.scss";
@import "main/auth/lock/lock.scss";
@import "main/auth/forgot-password/forgot-password.scss";
@import "main/auth/login/login.scss";
@import "main/auth/masquerade/masquerade.scss";
@import "main/auth/register/register.scss";
@import "main/auth/reset-password/reset-password.scss";
@import "main/hedgecovest/e-commerce/e-commerce.scss";
@import "main/hedgecovest/manager-admin/manager-admin.scss";
@import "main/hedgecovest/liquidation/manager-liquidation.scss";
@import "main/hedgecovest/manager-commentary/commentary.scss";
@import "main/hedgecovest/manage-returns/manage-returns.scss";
@import "main/hedgecovest/manager-dash/dash-notifi.scss";
@import "main/hedgecovest/manager-dash/manager-dash.scss";
@import "main/hedgecovest/model/model.scss";
@import "main/hedgecovest/portfolio-editor/portfolio-editor.scss";
@import "main/hedgecovest/reporting/reporting.scss";
@import "main/hedgecovest/model-changes-history/model-change-history.scss";
@import "main/hedgecovest/search/search.scss";
@import "quick-panel/tabs/activity/activity-tab.scss";
@import "quick-panel/tabs/chat/chat-tab.scss";
@import "quick-panel/tabs/today/today-tab.scss";
@import "quick-panel/tabs/news/news-tab.scss";
@import "toolbar/layouts/content-with-toolbar/toolbar.scss";
@import "toolbar/layouts/horizontal-navigation/toolbar.scss";
// endinjector


$toploader-bar-height: 2px;

#topLoader {
	md-progress-linear {
	  height: $toploader-bar-height;

	  	.md-container {
		    height: $toploader-bar-height;
		    background-color: transparent !important;

		    .md-bar {
		      height: $toploader-bar-height;
		    }

		    .md-dashed:before {
		      height: $toploader-bar-height;
		    }
		}
	}
}

.md-table-pagination {
	.buttons {

		.md-icon-button[disabled] {
			.md-default-theme {
				color: rgba(0,0,0,0.26) !important; // next page icon wasn't visible w/o this
			}
		}

		.md-default-theme {
			color: rgba(0,0,0,0.54);
		}
	}
}

.md-datepicker-button {
	md-icon { 
		color: rgba(0,0,0,0.54) !important; 
		fill: rgba(0,0,0,0.54) !important;
	}
}