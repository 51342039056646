.models-table {

	.paginator {
		.buttons {
		
			.md-icon-button[disabled] {
				.md-default-theme {
					color: rgba(0,0,0,0.26) !important; // next page icon wasn't visible w/o this
				}
			}

			.md-default-theme {
				color: rgba(0,0,0,0.54);
			}
		}
	}

}
.filter-list-container {
	max-height: 500px;
}
.filter-column-wrap {}
.fund-type-list {
	
	height: 0;
	max-height: 0;
	opacity: 0;
	overflow: hidden;
	transition: 0.3s all ease;
	transition-property: opacity, height, max-height;
	&.expanded {
	
		max-height: 1000px;
		height: auto;
		opacity: 1;
		overflow: hidden;
	}
}
// .expanded {
// 	max-height: 1000px;
// 	height: auto;
// 	opacity: 1;
// 	overflow: hidden;
// }

.filter-label {

	display: block;
	padding-bottom: 8px;
	.icon {
		transition: 0.3s all ease;
		&.expanded {
			transform: rotate(180deg);
		}
	}
}

