.new-return {
	background: #d9f1d9 !important;

	&:hover {}
}

.warning {
	background: #fbb2b2 !important;

	&:hover {}
}

#add-return-dialog {
	.heading {
		background: #EEE; 
		color: black !important;

		.icon-close {
			color: black !important;
			font-size: 20px;
		}
	}

	.percentage-input-wrap {
		input.return-percentage {
	      padding-right: 15px !important;
	    }

	    &::after {
	      content: "%";
	      position: absolute;
	      right: 2px;
	      top: 10px;
	    }
	}
}

// .md-datepicker-calendar-icon {
// 	color: rgba(0,0,0,0.54) !important;
// }

.model-performance-table {
	thead {
		tr {
			background: #EEE; 

			th {
				font-weight: bold !important;
				color: #5d5d5d !important;
			}
		}
	}

	tbody {
		tr {}
	}

	td:not(:first-child) {
		text-align: right !important;
	}

	th:not(:first-child) {
		text-align: center !important;
	}
}

.pending-changes {
	font-weight: bold;
	font-size: 1.1em;
}