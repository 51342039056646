@import '../../../../bower_components/angular-material/angular-material.scss';

@import 'partials/reset';
@import 'partials/variables';
@import 'partials/mixins';
@import 'partials/angular-material-extend';
@import 'partials/layouts-template';
@import 'partials/layouts-page';
@import 'partials/animations';
@import 'partials/colors';
@import 'partials/icons';
@import 'partials/material';
@import 'partials/typography';
@import 'partials/bootstrap-glyphicons';

//@import 'partials/plugins/nvd3';
@import 'partials/plugins/angular-google-map';
@import 'partials/plugins/angular-moment-picker';
//@import 'partials/plugins/datatable';
@import 'partials/plugins/highlight';
@import 'partials/plugins/perfect-scrollbar';
@import 'partials/plugins/text-angular';

@import 'partials/helpers';
@import 'partials/print';



@import url("//fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,300,600,700,800");
html,body{
	font-family: "Open Sans", Arial, sans-serif;
    font-weight: lighter;
    letter-spacing: normal;
}
.breadcrumb-wrap{
	margin-left:24px;

	md-icon.md-default-theme,
	md-icon{
		color:#2c557f;
	}
}
[md-theme="default"]{
	.md-primary-bg {
    	background-color: #2c557f !important;
    }
    .md-accent-bg {
    	background-color: #039be5;    	
    }
    .secondary-text,
    md-content.md-default-theme.md-hue-1,
    md-content.md-hue-1 {
    	color: rgba(0,0,0,1) !important;
    }
    md-content.md-default-theme.md-hue-1,
    md-content.md-hue-1 {
    	font-weight:400;
    }
    .md-tab.md-active{
    	background-color:#fff;
    }
    .md-headline {
	    margin-bottom: 10px;
	    display: block;
	}
	.md-button,
	md-dialog .md-button{
		min-height: 36px;
	}

	.ps-container > .ps-scrollbar-y-rail > .ps-scrollbar-y {
	    width: 10px;
	}
}

.max-1200{ max-width:1200px;margin:auto; }
.page-layout {
	> .header {
		.breadcrumb {
			color:#fff !important;
		}
	}
}
md-icon.md-default-theme,
md-icon{
	color:#fff;
}
.ms-navigation {
	.ms-navigation-node {
		.ms-navigation-item {
			> .ms-navigation-button.active,
			> .ms-navigation-button:hover{
				background-color:#376798 !important;
			}
			> .ms-navigation-button{
				font-size:16px;
			}

		}
	}
}
.margin-top-50{
	margin-top:50px;
}
.text-center{
	text-align:center;
}
.text-right{
	text-align:right;
}
.right{
	float:right;
}
.left{
	float:left;
}
md-sidenav {
    width: 260px;
}
[md-theme="default"] {

	.light-grey-bg{
		background-color:#eee;
	}

	.object-table-module{
		.sorting-container{

		}
		 .search {
		    width: 300px;
		    padding: 5px;
		    height: 30px;
		    border: 1px solid #eee;
		    margin: 10px 0;
		}
		.object-table{
			border-spacing:0;

			tr{
				border-color:#DEDEDE;

				td{ border-color:#DEDEDE; }
				th{
					border-bottom-width: 0px;
				}
				.md-header{
				    color: rgba(0, 0, 0, 0.54);
				    font-size: 12px;
				    font-weight: bold;
				    white-space: nowrap;
				}
			}
			tbody{
				tr.selected-row {
					td{
						background-color: #6E8EB0;
						color:#fff;
					}
				}
			}
			tfoot,thead {
				> tr{
					background-color: #DEDEDE;
					
					td{
						font-weight: 700;
						color:#000;
					}
				}
			}
		}
		.pagination-container{
			.pagination > li {
			    display: inline-block;
			}
			ul{
				margin:0;

				.nav-link{
					padding: 2px 3px;
				}
			}
		}
		.count{
			margin-top:0;
		}
	}

	.hcv-loader{
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		background: #f5f5f5;
		top: 0;
		left: 0;
		z-index: 99;

		.loader-wrap{
			position: relative;
			margin: 22% auto 0;
			display: block;
			text-align: center;

			img{
				margin: 0 auto;
				display: block;
			}
		}
	}
}

table.md-table:not(.md-row-select) td.md-cell:nth-child(n+2):nth-last-child(n+2),
table.md-table:not(.md-row-select) th.md-column:nth-child(n+2):nth-last-child(n+2){
	padding: 0 5px 0 0;
}