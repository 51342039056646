.onboarding-checklist-wrap{
	ul{
		list-style: none;
		width: 100%;
		display:block;

		li{
			margin-bottom: 45px;
		}

		li:nth-child(odd){
			background-color: #F0F0F0;
			padding:5px;
		}
	}

}
#e-commerce-product .picker-wrap{
	> label {
		margin-left: 63px;
	}
}
#e-commerce-product .md-datepicker-input-container{
	width: 85%;
}
#e-commerce-product .md-button.md-icon-button{
	margin-top: -15px;
}

.model-detail-form .step-instructions{
	font-size: 20px;
}
.model-facets{
	.md-select-value{
		>span{
			display:flex;
			font-size:16px;

			// >*:first-child {
			// 	display:none;
			// }
			.md-container{
				margin-left: 5px;
			}
		}
	}
}
#main{
	.admin-models-table{
		tbody{
			a.md-button.edit-button{
				background-color: rgba(158,158,158,0.2);
				-webkit-transition: background-color 350ms ease-out;
			    -moz-transition: background-color 350ms ease-out;
			    -o-transition: background-color 350ms ease-out;
			    transition: background-color 350ms ease-out;
			}
			a.md-button.edit-button:hover{
				background-color: rgba(158,158,158,0.7);
			}
		}
	}
}
.add-new-personnel-dialog {
	width:500px;
	padding: 15px;
}
.add-new-personnel-dialog .dialog-title{
	text-align: center;
}

.drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    width: 100%;
    text-align: center;
    padding: 50px 0;
    margin: 0;
}
.dragover {
    border: 5px dashed blue;
}
.dropzone-image{
	width: 150px;
	height: 150px;
	display: block;
	margin: auto;
}
.model-detail-form md-list-item.personnel-list{
	margin-bottom:25px !important;
}
.model-detail-form md-list-item.personnel-list > div{
	padding: 10px !important;
}
.add-new-personnel-dialog .dialog-title{
	margin: 0;
}
.ta-root .ta-text.ta-editor{
	overflow-x: hidden !important;
}
// .add-new-personnel-dialog .dropzone-wrap{
// 	text-align: center;
// 	border: 1px dashed #ccc;
// 	padding: 50px 0;
// }
