#quick-panel {

    .today-tab {
        .date {
            > md-list-item {
                min-height: 115px;
            }
        }
    }
    .onboarding-checklist {
    	md-list-item.md-2-line .md-list-item-text,
    	md-list-item.md-2-line > .md-no-style .md-list-item-text,
    	md-list-item.md-3-line .md-list-item-text,
    	md-list-item.md-3-line > .md-no-style .md-list-item-text{
    		max-width:83% !important;
    		margin-bottom: 25px;

    		h3{
    			white-space:normal;
    			line-height: 1.4;
    		}
    		a{
    			word-wrap: break-word;
    		}
    	}

        md-list {
            padding-bottom:0px;
        }
        .checklist {
        	height:auto;
        	min-height:0;
        	margin-bottom: 15px;
            border-bottom: 1px solid #eee;
            padding-bottom: 10px;

            .status {
                margin: 24px 20px 5px 8px;

                &.normal {
                    color: #00C853;
                    margin-top:5px;
                    margin-bottom:5px;
                }

                &.warn {
                    color: #D50000;
                }

                &.error {
                    color: #FF6D00;
                }
	            +.md-list-item-text{
	            	margin-top: 0;
	            	margin-bottom: 0;
	            }
            }
        }
        .checklist:last-child {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}