#model-changes-history{

	.table-controls {
		.buttons {
		
			.md-icon-button[disabled] {
				.md-default-theme {
					color: rgba(0,0,0,0.26) !important; // next page icon wasn't visible w/o this
				}
			}

			.md-default-theme {
				color: rgba(0,0,0,0.54);
			}
		}
	}

	.export-btn {}

	th {
		text-align: left;
	}

	td {
		text-align: left;
	}

	.picker-wrap {
		.md-icon.md-default-theme, md-icon {
			color: rgba(0, 0, 0, 0.54) !important;
		}
	}

	.filter-controls {
		.filter-btn {
			min-height: 0 !important;
			line-height: 29px !important;
		}
	}

	.search-input {
		border-bottom: 1px solid #EEE;
		// line-height: 29px;
		padding-left: 5px;
		border-radius: 3px;
		padding-bottom: 5px;
	}

	.clear-btn {

		a {
			transition: none !important; -webkit-transition: none !important; 
			color: rgb(3,155,229); 
			font-size: .8em;

			&:hover {
				padding-left: 0 !important;
				color: #2c557f !important;
			}
		}
	}

	.object-table-module{

		padding: 8px;

		
	    .pagination-container{
	      ul{
	        .nav-link{
	          border-radius: 0;
	        }
	      }
	    }

		/* hacky stuff to line up the table search box and its icon */
		.form-group {
			position: relative;
		}
		
	    input.search {
			// margin-right: 8px;

			+ i {
				background: white;
				font-size: 18px;
				margin-top: 5px;
				margin-right: 2px;
			}

	    }

	    /* end hacky stuff */
	}

	.models-table {
		border-bottom: 1px solid #EEE;
	}
}