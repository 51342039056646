#reporting {
	md-card {
		background-color: #FFF !important;
	}
	.paginator {
		.buttons {

			.md-icon-button[disabled] {
				.md-default-theme {
					color: rgba(0,0,0,0.26) !important; // next page icon wasn't visible w/o this
				}
			}

			.md-default-theme {
				color: rgba(0,0,0,0.54);
			}
		}
	}

	.md-datepicker-button {
		md-icon { 
			color: rgba(0,0,0,0.54) !important; 
		}
	}

	p { font-weight: 700; }

	// override md-table default padding
	.table-headers th.md-column:nth-child(n+2) {
	    padding: 0 25px 0 0 !important;
	}

	.table-header-row{
		th{
			&:first-child{
				text-align: center !important;
			}
		}
		th:nth-child(n+2) {
			text-align: right !important;
		}
	}

	tbody tr {
		td:first-child{
			text-align: center !important;
		}
	}

	md-checkbox {
		margin-bottom: 0 !important;
	}

	.dispersion {
		tr {
			td:first-child{
				text-align: left !important;
			}
		}
	}
}
