
$table-bg:                      #fff;
$table-bg-accent:               #f5f5f5;
$table-bg-hover:                rgba(0,0,0,.12);
$table-bg-active:               $table-bg-hover;
$table-border-color:            #e0e0e0;
$table-cell-padding: 			5px 3px;
$table-header-font-weight:		500;
$table-header-font-color:		#000;
$table-condensed-cell-padding:	$table-cell-padding;
$table-border-color:			$table-header-font-color;
$negative-color:				#f26203;

[md-theme="default"]{

  .table {
    font-size:1.2rem;
  }
	.negative_num{color:$negative-color;}
	.no-margin{margin-top:0; margin-bottom:0; padding-top:0; padding-bottom:0;}
	md-toolbar.md-default-theme:not(.md-menu-toolbar),
	md-toolbar:not(.md-menu-toolbar){
		background:none;

		.md-toolbar-tools{
			font-size:16px;
			height: 42px;

			a{
				color:#2D323E;
				-webkit-transition: padding .35s;
				transition: padding .35s;
			}
			a:hover{
				padding-left:8px;
			}
		}
		.md-toolbar-tools.active{
			background-color: #337ab7;

			a{
				padding-left: 8px;
				color: #fff;
			}
		}

	}
  .model-header {
    border-bottom: 1px solid #EEE;
    margin: 0;
    margin-bottom: 1em;

    md-icon.md-default-theme,
    md-icon{
      color: #2c557f;
      margin-top: 5px;
    }
  }
  
	.model-content{
    font-size:14px;
    //white-space: pre-wrap;
  }
  .pending-model-warning{
    text-align:center;
    background:#323232;
    border-radius:3px;
    color:#fff;
    padding:10px;
    font-weight: 600;
  }

  /* statistics tab */
  .statistics-wrap {

    .statistics-table {
      thead {
        tr {
          th {
            &:nth-child(1){ width: 30%; }
            &:nth-child(2){ width: 50%; }
            &:nth-child(3){ width: 20%; }
          }
        }
      }
    }
  }

  /* /statistics tab */

  /* treemap */
  #treemap {
    .btn-wrap {
      position: absolute; 
      z-index: 1;
    }

    .chart {
      min-height: 300px;
    }
  }

  .bio {


  	.bio-thumbnail{
      float: left;
      margin-right: 15px;
  	}
  	.personnel-description{ 
      p {
        margin-bottom: 20px;

      }
      ul {
        display: table;
        margin-top: 0;
        padding-left: 20px;

        li {


            position: relative;
            //left: 1em;
            padding-right: 1em;

          ul {
            list-style-type: circle;
          }
        }
      }
      
    }
  }

	.fund-manager{
		font-weight:500;
	    font-size: 18px;
	    margin-top: 25px;
	}
	.fund-position{ font-size:14px; }
	.model-rundown {
		background: #2c557f;
		color: #fff;
		padding: 0;
		margin-bottom: 15px;
		// margin-top: -32px;

		.quad-boxes {
			background: #293f56;

			> div {
			    height: 59.5px;
			    padding: 0;
			    .title {
			    	display: block;
				    font-size: 12px;
				    width: 100%;
				    background: #2c557f;
				}
				.number {
				    font-size: 24px;
				    background: #293f56;
				}
			}
			> div:first-child {
			    border-bottom: 1px solid #000;
			}
			> div:nth-child(odd) {
			    border-left: 1px solid #000;
			}
		}
		.row-rundown {
		    border: 1px solid #000;
		    border-bottom-color: #293f56;
		    padding: 0;
		    padding-top: 3px;

		    > div {
			    height: 28.5px;
			    margin-left: 0;
			    margin-right: 0;
			    padding: 5px;
			}
			> div:nth-child(even) {
			    background: #293f56;
			}
			.pull-right {
			    float: right;
			}
		}
		.risk-rundown {
		    padding: 0;
		    padding-top: 3px;
		     > div {
			    height: 56px;
			}
			.title {
			    font-size: 12px;
			}
			.number {
			    font-size: 24px;
			    color: #8cb843;
			    width: 100%;
			    display: block;
			}
		}
		.disclaimer {
		    border-top: 1px solid #000;
		    font-size: .8em;
		    padding: 3px 0;
		}
	}

  .related-news-wrap{
    #searchFilterWrap{
      position:relative;
      height:30px;

      #searchForm{
        display:block;
        float:left;

        #searchBox{
          border-radius: 25px;
          border:1px solid #ccc;
          padding:5px 8px;
          width: 300px;
          max-width: 100%;
        }
      }
      #sortByWrap{
        float:right;
        font-size: 15px;
      }
    }
    .fund-related-news {
      background: none;
        box-shadow: none;
        margin: 10px 0;
        list-style:none;
        padding: 0;

        .angular-grid-item,
        .masonry-brick{
          margin:8px;
          padding:0 16px;
          background-color: rgb(245,245,245);
          border-radius: 2px;
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
          max-width: 31%;

          .grid-header{
            display: block;
            position: relative;
            height:70px;

            .article-title{
              h3{
                margin: 10px 0 0;
              }
            }
            .article-date {
              margin-bottom:10px;
            }
          }
          .commentary-pdf-previewer,
          .commentary-video {
            max-width:100%;
          }

          .footer{
            display:block;
            width:100%;
          }
        }
        /*
        .masonry-brick.wide2 {
          max-width: 66%;
        }
        */
    }

    .grid {
        border: 1px solid #cccccc;
        list-style: none;
        background: #ffffff;
        box-sizing: border-box;
        -moz-box-sizing : border-box;
        -webkit-transition: all 0.6s ease-out;
        transition: all 0.6s ease-out;
    }
    .grid-img {
        width: 100%;
        vertical-align: middle;
        -webkit-transition: opacity 0.6s ease-out;
        transition: opacity 0.6s ease-out;
        background-color: #fff;
        opacity: 0;
        visibility: hidden;
    }
     
    .grid-img.img-loaded{
        visibility: visible;
        opacity: 1;
    }

    .firm-overview-body { white-space: pre-line; }


  }

  .performance-wrap {
    .model-performance-table {
      border-collapse: collapse; margin-bottom: 0;

      th {
        white-space: nowrap;
      }
    }
  }

  .diligence-wrap{
    
    ul{
      list-style:none;

      li{
        margin-bottom:10px;

        i.icon{
          color: #5eb55e;
        }
      }
    }
  }

  md-tabs-wrapper{
    md-icon.md-default-theme, md-icon {
      color: #2c557f;
    }
  }
  .fact-sheet-download{
    font-size:16px;
  }


	// Baseline styles
.table {
  width: 100%;
  max-width: 100%;
  border: none;
  background-color: $table-bg;
  border: none;

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        text-align: left;
        padding: 10px;
        
        vertical-align: top;
        border-top: 0;

        &:not(.new-return):hover {
          background: rgba(#DDD, 0.5) !important;
        }
      }
    }
  }
  > thead > tr > th {
    font-weight: 500;
    color: $table-header-font-color;
    vertical-align: bottom;
    border-bottom: 1px solid rgba(0,0,0,.12);
  }
  > caption + thead,
  > colgroup + thead,
  > thead:first-child {
    > tr:first-child {
      > th,
      > td {
        border-top: 0;
      }
    }
  }
  > tbody + tbody {
    border-top: 1px solid rgba(0,0,0,.12);
  }



  // Nesting
  .table {
    background-color: $table-bg;
  }

  // Remove border
  .no-border {
    border: 0;
  }
}

// Condensed table w/ half padding
.table-condensed {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-condensed-cell-padding;
      }
    }
  }
}


.dynamic-grid, .fund-related-news{
  li{
    display:block;

    .pdf-wrapper{
      position:absolute;
      top: 0;
      left: 0;
      background: rgba(0,0,0,.8);
      width: 100%;
      height: 100%;
      padding: 10px;

      transition: opacity .35s linear;
      -webkit-transition: opacity .35s linear;
      opacity: 0;
    

      .area-wrap{
        position: relative;
        display: block;
        width: 100%;
        height: 100%;

        .area {
          width: 100%;
          height: 100%;
          position: relative;

          .bubble {
            position: absolute;
            width: 100%;
            height: 100%;
            display: table;

            h3 {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                padding: 8px;

                a{ color:#fff; }

                .date{
                  clear: both;
                  display: block;
                }
            }
          }
        }
      }
    }
  }

  li:hover{

    .pdf-wrapper{
      opacity: 1;

      .area-wrap{
        .area {

          .bubble {

            h3 {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
                padding: 8px;
            }
          }
        }
      }
    }
  }
}


// Bordered version
//
// Add horizontal borders between columns.
.table-bordered {
  border: 0;
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border: 0;
        border-bottom: 1px solid $table-border-color;
      }
    }
  }
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 2px;
    }
  }
}

.model-view-content{
  overflow:hidden;
}


// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)
.table-striped {
  > tbody > tr:nth-child(odd) {
    > td,
    > th {
      background-color: $table-bg-accent;
    }
  }
}

// Hover effect
//
.table-hover {
  > tbody > tr:hover {
    > td,
    > th {
      background-color: $table-bg-hover;
    }
  }
}

// Responsive tables (vertical)
//
// Wrap your tables in `.table-responsive-vertical` and we'll make them mobile friendly
// by vertical table-cell display. Only applies <768px. Everything above that will display normally.
// For correct display you must add 'data-title' to each 'td'
.table-responsive-vertical {

  @media screen and (max-width: 768px) {

    // Tighten up spacing
    > .table {
      margin-bottom: 0;
      background-color: transparent;
      > thead,
      > tfoot {
        display: none;
      }

      > tbody {
        display: block;

        > tr {
          display: block;
          border: 1px solid $table-border-color;
          border-radius: 2px;
          margin-bottom: $table-cell-padding;

          > td {
            background-color: $table-bg;
            display: block;
            vertical-align: middle;
            text-align: right;
          }
          > td[data-title]:before {
            content: attr(data-title);
            float: left;
            font-size: inherit;
            font-weight: $table-header-font-weight;
            color: $table-header-font-color;
          }
        }
      }
    }
    
    // Special overrides for shadows
    &.shadow-z-1 {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      > .table > tbody > tr {
        border: none;
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > tbody {
        > tr {
          > td {
            border: 0;
            border-bottom: 1px solid $table-border-color;
          }
          > td:last-child {
            border-bottom: 0;
          }
        }
      }
    }

    // Special overrides for the striped tables
    > .table-striped {
      > tbody > tr > td,
      > tbody > tr:nth-child(odd) {
          background-color: $table-bg;
      }
      > tbody > tr > td:nth-child(odd) {
          background-color: $table-bg-accent;
      }
    }

    // Special overrides for hover tables
    > .table-hover {
      > tbody {
        > tr:hover > td,
        > tr:hover {
          background-color: $table-bg;
        }
        > tr > td:hover {
          background-color: $table-bg-hover;
        }
      }
    }
  }
}
	
}

