
.no-transition{
    -moz-transition: none;
    -webkit-transition: none;
    -o-transition: color 0 ease-in;
	transition: none;
}
#model-performance-chart{
	.white-bg{
		background:#fff;
	}
}
.widget-group{
	.secondary-text{ color:#fff !important; }

	.md-icon-button{
		md-icon[md-font-icon]{
			font-size:.95em;
		}
	}
}
// .nv-barsWrap.nvd3-svg,
// .nv-linesWrap.nvd3-svg{
// 	padding:20px;
// 	display:block;
// }
// .nvd3-chart-wrap{

// 	position: relative;

// 	.empty-state {
// 		width:100%;
// 		height:500px;
// 		display:block;
// 		background:#fff;
// 		position:absolute;
// 		top:0;
// 		left:0;

// 		i {
// 			margin:150px auto 0;
// 			display:block;
// 			text-align:center;
// 			color:#607d8b;
// 		}
// 	}

// 	.nvd3.nv-wrap.nv-linePlusBar{

// 		.nv-barsWrap.nvd3-svg{

// 			g.nv-bars{
// 				rect{
// 					max-width:30px;
// 				}
// 			}
// 		}
// 		.nv-barsWrap.nvd3-svg{
// 			background:#fff;
// 			display:block;
// 			fill:#ffffff;
// 		}
// 	}
// 	#G { background:#F5F5F5;width:300px;height:370px;display:block; }
// }




.helpDialog{
	
	md-tabs-wrapper{
		display: none;
	}
	.tab-hero{
		width:100%;
		height: 250px;
		display:block;
		overflow: hidden;

		.hero-img{
			height:100%;
			width:100%;
			display:block;
			background-size:cover;
			background-position:center center;
			border:2px solid #2c557f;
		}
	}

	.tab-instructions{
		h2{font-weight:800; margin-top: 0;}
		p{font-weight:500;}
	}

	md-dialog-actions{
		background:#607d8b;

		.md-button{
			background:#fff;
		}
	}
	.pagination-wrap{
		text-align: center;

		.pagination > li{
			margin: 0 5px;
			width: 10px;
		    height: 10px;
		    display: inline-block;

			> a{
				background: #000;
				color: #000;
				border: 0;
				width: 10px;
			    height: 10px;
			    border-radius: 10px;
			    overflow: hidden;
			    padding: 0;
				opacity: .6;
			}
			> a.active{
				background: #428bca;
				color: #428bca;
				opacity: 1;
			}
		}
	}





	#popupContainer {
	  position: relative; }

	.footer {
	  width: 100%;
	  text-align: center;
	  margin-left: 20px; }

	.footer, .footer > code {
	  font-size: 0.8em;
	  margin-top: 50px; }

	button {
	  width: 200px; }

	div#status {
	  color: #c60008; }

	.dialog-demo-prerendered md-checkbox {
	  margin-bottom: 0; }	
}