#masquerade {
    height: 100%;
    /* background: url('/assets/images/backgrounds/busy_downtown2.jpg') no-repeat; */
    background: url('/mngr/assets/images/backgrounds/busy_downtown2.jpg') no-repeat;
    background-size: cover;

    #masquerade-form-wrapper {
        flex: 1 0 auto;
        padding: 32px;

        #masquerade-form {
            width: 384px;
            max-width: 384px;
            padding: 32px;
            text-align: center;
            background: #FFFFFF;

            .logo {
                height: 60px;
                margin: 32px auto 0;
                background: url('/sites/default/files/SMArtX-logo-new_0.png') no-repeat;
                background-size:contain;
                background-position:center;

                span{ display:none; }
            }

            .title {
                font-size: 17px;
                margin: 16px 0 32px 0;
            }

            form {
                width: 100%;
                text-align: left;

                .submit-button {
                    width: 220px;
                    margin: 16px auto;
                    display: block;
                }
            }

            .login {
                margin: 32px auto 24px auto;
                width: 250px;
                font-weight: 500;

                .text {
                    margin-right: 8px;
                }

                .link {

                }
            }
        }
    }
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #masquerade {

        #masquerade-form-wrapper {
            padding: 16px;

            #masquerade-form {
                padding: 24px;
                width: 100%;

                form {

                    .md-button {
                        width: 90%;
                    }
                }
            }
        }
    }

}