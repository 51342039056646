#quick-panel {
    
    .recent-news-wrap{
        .news{
           
        }
        .md-list-item-text{
            max-width:100%;
            height:auto;

            h3{
                word-wrap:break-word;
                white-space:normal;
            }
        } 
    }
}